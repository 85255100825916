









































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import MultipleChoiceTemplate from '@/components/question/templates/MultipleChoiceTemplate.vue';
import EssayTemplate from '@/components/question/templates/EssayTemplate.vue';
import {
  GeneralEssayQuestionBody,
  GeneralMCQQuestionBody,
  GeneralHomeworkQuestionBody,
  UpdateHomeworkQuestions,
  UpdateHomeworkEssayQuestionRequest,
  UpdateHomeworkMCQQuestionRequest,
} from '@/models/question_banks';
import {
  AcceptedImageFileType,
  AcceptedVideoFileType,
  MaximumMCQInputs,
  MinimumMCQInputs,
  QuestionTypeNames,
} from '@/utils/constants';
import { DropdownItem } from '@/models/dropdown';
import { EssayItem, MultipleChoiceItem } from '@/models/miscellaneous';
import Upload from '@/components/common/Upload.vue';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
// TinyMCE and MathType Implementation
import Editor from '@tinymce/tinymce-vue';
import { ToastProgrammatic } from 'buefy';
// We explicitly declare like this to enable mathtype to work
(window.globalThis as any).tinymce = require('tinymce');
require(`@wiris/mathtype-tinymce5`);
@Component({
  components: {
    Dropdown,
    Editor,
    MultipleChoiceTemplate,
    EssayTemplate,
    Upload,
  },
})
export default class HomeworkQuestionFormTemplate extends Vue {
  tinymceScript: HTMLScriptElement | null = null;
  isCurrentTypeInvalid = false;
  minimumMCQInputs = MinimumMCQInputs;
  maximumMCQInputs = MaximumMCQInputs;
  // Category Dropdowns
  selectedCourse = '- Select -';
  selectedHomework = '- Select -';
  isHomeworkDropdownDisabled = true;
  // MCQ and Essay Properties
  selectedIsAvailable = true;
  selectedQuestionName = ``;
  selectedQuestionAsset: UploadAttachmentWithThumbnail | null = null;
  selectedQuestionText = ``;
  selectedDefaultMark = ``;
  selectedGeneralFeedback = ``;
  selectedShuffleChoices: boolean = false;
  selectedOneOrMultipleAnswers = '- Select -';
  selectedNumberTheChoices = '- Select -';
  selectedStandardInstructions = '- Select -';
  // General Form Data
  currentGeneralQuestionBody: GeneralHomeworkQuestionBody = {
    id: -1,
    courseId: -1,
    homeworkId: -1,
    questionType: ``,
    availability: true,
    questionName: ``,
    questionText: ``,
    defaultMark: -1,
    generalFeedback: ``,
    questionAsset: ``,
  };
  // MCQ
  currentMCQQuestionBody: GeneralMCQQuestionBody = {
    areChoicesShuffled: false,
    hasMultipleAnswers: null,
    choiceNumberType: '',
    showStandardInstructions: null,
    choices: [],
  };
  currentMCQInputs: MultipleChoiceItem[] = [];
  //Essay
  currentEssayQuestionBody: GeneralEssayQuestionBody = {
    answerText: ``,
    requireText: ``,
  };
  currentEssayInput: EssayItem = {
    answerText: ``,
    requireText: ``,
  };
  isCurrentFormValid = false;
  // Image Upload State
  isQuestionImageAdded = false;

  // Edit Variables
  previousSelectedCourse = ``;
  previousSelectedHomework = ``;
  previousSelectedAssetUrl = ``;
  previousSelectedAnswerOption = ``;
  previousSelectedAnswerDisplay = ``;
  previousSelectedStandardInstruction = ``;

  @Prop()
  currentQuestionData!: UpdateHomeworkQuestions;

  @Prop({ default: false })
  isEditMode!: boolean;

  @Prop({ required: true })
  courseData!: DropdownItem[];

  @Prop({ required: true })
  homeworkData!: DropdownItem[];

  @Prop({ required: true })
  questionAnswerOptionData!: DropdownItem[];

  @Prop({ required: true })
  questionAnswerDisplayData!: DropdownItem[];

  @Prop({ required: true })
  questionStandardInstructionData!: DropdownItem[];

  @Prop()
  questionCoursePrefixId!: number;

  @Prop()
  questionHomeworkPrefixId!: number;

  get courses() {
    return this.courseData;
  }

  get homeworks() {
    return this.homeworkData;
  }

  get answerSelectionOptions() {
    return this.questionAnswerOptionData;
  }

  get answerDisplayOptions() {
    return this.questionAnswerDisplayData;
  }

  get answerStandardInstructionData() {
    return this.questionStandardInstructionData;
  }

  get currentType() {
    return this.isEditMode && 'hasMultipleAnswers' in this.currentQuestionData
      ? QuestionTypeNames.mcq
      : this.isEditMode && 'answerText' in this.currentQuestionData
      ? QuestionTypeNames.essay
      : (this.$route.query[`type`] as string);
  }

  get isCurrentTypeMCQ() {
    return this.isEditMode
      ? 'hasMultipleAnswers' in this.currentQuestionData
      : this.currentType === QuestionTypeNames.mcq;
  }

  get isCurrentTypeEssay() {
    return this.isEditMode
      ? 'answerText' in this.currentQuestionData
      : this.currentType === QuestionTypeNames.essay;
  }

  get assetFileTypes() {
    return [...AcceptedImageFileType, ...AcceptedVideoFileType];
  }

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  get QuestionTextConfig() {
    return {
      height: 300,
      menubar: false,
      external_plugins: {
        tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor |\
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help |\
                tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      draggable_modal: true,
    };
  }

  mounted() {
    if (!this.isEditMode) {
      for (let index = 0; index < MinimumMCQInputs; index++) {
        this.currentMCQInputs.push({
          id: index,
          choiceText: ``,
          feedbackText: ``,
          isAnswerValid: false,
        });
      }
    }

    if (this.isEditMode && this.currentQuestionData) {
      this.repopulateQuestionData();
    }
  }

  @Watch(`questionCoursePrefixId`)
  autofillCourseSelection() {
    if (this.questionCoursePrefixId > -1) {
      this.previousSelectedCourse = this.questionCoursePrefixId.toString();
    }
  }

  @Watch(`questionHomeworkPrefixId`)
  autofillHomeworkSelection() {
    if (this.questionHomeworkPrefixId > -1) {
      this.previousSelectedHomework = this.questionHomeworkPrefixId.toString();
    }
  }

  repopulateQuestionData() {
    // After checking, we can fill in. This only applies for Edit
    if (this.currentQuestionData) {
      this.currentGeneralQuestionBody = this.currentQuestionData;
      this.previousSelectedCourse = this.currentGeneralQuestionBody.courseId.toString();
      this.previousSelectedHomework = this.currentGeneralQuestionBody.homeworkId.toString();
      this.selectedQuestionName = this.currentGeneralQuestionBody.questionName;
      this.selectedQuestionText = this.currentGeneralQuestionBody.questionText;
      this.selectedDefaultMark = this.currentGeneralQuestionBody.defaultMark.toString();
      this.selectedGeneralFeedback = this.currentGeneralQuestionBody.generalFeedback;
      this.selectedIsAvailable = this.currentGeneralQuestionBody.availability;
      this.previousSelectedAssetUrl = this.currentGeneralQuestionBody.questionAsset as string;

      // This property is specifically for MCQ
      if ('hasMultipleAnswers' in this.currentQuestionData) {
        this.selectedShuffleChoices = this.currentQuestionData.areChoicesShuffled as boolean;
        this.previousSelectedAnswerOption = this.currentQuestionData.hasMultipleAnswers
          ? 'multiple'
          : 'one';
        this.previousSelectedAnswerDisplay = this.currentQuestionData.choiceNumberType;
        this.previousSelectedStandardInstruction = this.currentQuestionData.showStandardInstructions
          ? 'yes'
          : 'no';
        this.currentMCQInputs = [];
        this.currentQuestionData.choices.forEach((data) => {
          this.currentMCQInputs.push({
            id: data.id,
            choiceText: data.choiceText,
            isAnswerValid: data.isAnswerValid,
            feedbackText: data.feedbackText,
          });
        });
      }

      // This property is specifically for Essay
      if ('answerText' in this.currentQuestionData) {
        this.currentEssayInput = {
          answerText: this.currentQuestionData.answerText,
          requireText: this.currentQuestionData.requireText,
        };
      }
    }
  }

  // Increase Functions
  increaseChoiceCount() {
    this.currentMCQInputs.push({
      id: this.currentMCQInputs.length + 1,
      choiceText: ``,
      isAnswerValid: false,
      feedbackText: ``,
    });
  }

  decreaseChoiceCount(item: MultipleChoiceItem) {
    // Reset
    const tempStorage: MultipleChoiceItem[] = [];
    this.currentMCQInputs
      .filter((data) => {
        return data.id !== item.id;
      })
      .forEach((data, index) => {
        data.id = index + 1;
        tempStorage.push(data);
      });
    this.currentMCQInputs = tempStorage;
  }

  // Update Functions
  updateCourseState(data: DropdownItem) {
    this.selectedCourse = data.text;
    this.currentGeneralQuestionBody.courseId = parseInt(data.value);
    this.isHomeworkDropdownDisabled = false;
  }

  updateCourse(data: DropdownItem) {
    this.updateCourseState(data);
    this.isHomeworkDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.currentGeneralQuestionBody.courseId > 0) {
      this.isHomeworkDropdownDisabled = false;
      this.selectedHomework = '- Select -';
      this.currentGeneralQuestionBody.homeworkId = 0;
      this.retrieveHomeworkDropdown();
    }
  }

  updateHomeworkState(data: DropdownItem) {
    this.selectedHomework = data.text;
    this.currentGeneralQuestionBody.homeworkId = parseInt(data.value);
  }

  updateHomework(data: DropdownItem) {
    this.updateHomeworkState(data);
    this.validateCurrentForm();
  }

  updateIsAvailable(payload: boolean) {
    this.selectedIsAvailable = payload;
    this.currentGeneralQuestionBody.availability = payload;
    this.validateCurrentForm();
  }

  updateQuestionText(data: string) {
    this.selectedQuestionText = data;
    this.currentGeneralQuestionBody.questionText = data;
    this.validateCurrentForm();
  }

  updateQuestionName(data: string) {
    this.selectedQuestionName = data;
    this.currentGeneralQuestionBody.questionName = data;
    this.validateCurrentForm();
  }

  updateQuestionAsset(data: UploadAttachmentWithThumbnail | null) {
    this.selectedQuestionAsset = data;
    this.currentGeneralQuestionBody.questionAsset = data;
    this.validateCurrentForm();
  }

  updateDefaultMark(data: string) {
    this.selectedDefaultMark = data;
    this.currentGeneralQuestionBody.defaultMark = parseInt(data);
    this.validateCurrentForm();
  }

  updateGeneralFeedback(data: string) {
    this.selectedGeneralFeedback = data;
    this.currentGeneralQuestionBody.generalFeedback = data;
    this.validateCurrentForm();
  }

  // MCQ Functions
  updateShuffleChoices(value: boolean) {
    this.selectedShuffleChoices = value;
    this.validateCurrentForm();
  }

  updateAnswerSelection(data: DropdownItem) {
    this.selectedOneOrMultipleAnswers = data.text;
    this.currentMCQQuestionBody.hasMultipleAnswers = data.value === 'multiple';
    this.validateCurrentForm();
  }

  updateAnswerDisplay(data: DropdownItem) {
    this.selectedNumberTheChoices = data.text;
    this.currentMCQQuestionBody.choiceNumberType = data.value;
    this.validateCurrentForm();
  }

  updateStandardInstructions(data: DropdownItem) {
    this.selectedStandardInstructions = data.text;
    this.currentMCQQuestionBody.showStandardInstructions = data.value === 'yes';
    this.validateCurrentForm();
  }

  updateMultipleChoiceItems(data: MultipleChoiceItem) {
    const index = this.currentMCQQuestionBody.choices.findIndex((item) => {
      return item.id === data.id;
    });
    if (index > -1) {
      this.currentMCQQuestionBody.choices[index] = data;
    } else {
      this.currentMCQQuestionBody.choices.push(data);
    }
    this.validateCurrentForm();
  }

  // Essay
  updateEssayInput(data: EssayItem) {
    this.currentEssayQuestionBody.requireText = data.requireText;
    this.currentEssayQuestionBody.answerText = data.answerText;
    this.validateCurrentForm();
  }

  // Validation
  validateCurrentForm() {
    this.isCurrentFormValid = true;
    // Check for MCQ Choices first
    if (
      this.currentType === QuestionTypeNames.mcq &&
      this.currentMCQQuestionBody.hasMultipleAnswers !== null &&
      this.currentMCQQuestionBody.choices.length >= MinimumMCQInputs
    ) {
      this.validateChoices();
    }

    // MCQ Specific Section
    if (
      this.currentType === QuestionTypeNames.mcq &&
      (this.currentMCQQuestionBody.areChoicesShuffled === null ||
        this.currentMCQQuestionBody.hasMultipleAnswers === null ||
        this.currentMCQQuestionBody.choiceNumberType.length < 1 ||
        this.currentMCQQuestionBody.showStandardInstructions === null ||
        this.currentMCQQuestionBody.choices.length < MinimumMCQInputs)
    ) {
      this.isCurrentFormValid = false;
    }

    // Essay Specific Section
    if (
      this.currentType === QuestionTypeNames.essay &&
      this.currentEssayQuestionBody.requireText.length < 1 &&
      this.currentEssayQuestionBody.answerText.length < 1
    ) {
      this.isCurrentFormValid = false;
    }

    // General Section
    if (
      this.currentGeneralQuestionBody.courseId < 1 ||
      this.currentGeneralQuestionBody.homeworkId < 1 ||
      this.currentGeneralQuestionBody.questionName.length < 1 ||
      this.currentGeneralQuestionBody.questionText.length < 1 ||
      this.currentGeneralQuestionBody.defaultMark < 1 ||
      this.currentGeneralQuestionBody.generalFeedback.length < 1
    ) {
      this.isCurrentFormValid = false;
    }
  }

  @Watch(`isCurrentFormValid`)
  showValidatePrompt() {
    if (this.isCurrentFormValid) {
      ToastProgrammatic.open({
        duration: 4000,
        type: `is-dark`,
        message: `Validation Complete. Question is good to be saved`,
      });
    }
  }

  // MCQ specific validation
  validateChoices() {
    if (this.currentMCQQuestionBody.hasMultipleAnswers !== null) {
      this.isCurrentFormValid = true;
      const hasMultipleAnswers = this.currentMCQQuestionBody.hasMultipleAnswers;

      let numberOfTrueAnswers = 0;
      this.currentMCQQuestionBody.choices.forEach((data) => {
        if (data.isAnswerValid) {
          numberOfTrueAnswers++;
        }
        if (data.choiceText.length < 1) {
          this.isCurrentFormValid = false;
        }
      });
      if (
        numberOfTrueAnswers === 0 ||
        (hasMultipleAnswers === false && numberOfTrueAnswers > 1) ||
        (hasMultipleAnswers === true && numberOfTrueAnswers <= 1)
      ) {
        this.isCurrentFormValid = false;
      }
    }
  }

  // Emits
  @Emit(`retrieveHomeworks`)
  retrieveHomeworkDropdown() {
    return this.currentGeneralQuestionBody.courseId;
  }

  @Emit(`update`)
  updateCurrentSource() {
    switch (this.currentType) {
      case QuestionTypeNames.mcq: {
        const output: UpdateHomeworkMCQQuestionRequest = {
          ...this.currentGeneralQuestionBody,
          ...this.currentMCQQuestionBody,
          questionType: this.currentType,
        };
        return output;
      }
      case QuestionTypeNames.essay: {
        const output: UpdateHomeworkEssayQuestionRequest = {
          ...this.currentGeneralQuestionBody,
          ...this.currentEssayQuestionBody,
          questionType: this.currentType,
        };
        return output;
      }
      default: {
        break;
      }
    }
  }

  redirectToHomeworkManagement() {
    this.$router.push({
      path: `/homeworks`,
    });
  }
}
