


























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import QuestionsLayout from '@/layouts/question/QuestionsLayout.vue';
import HomeworkQuestionFormTemplate from '@/components/homework/HomeworkQuestionFormTemplate.vue';
import { CourseStores } from '@/store/course';
import { HomeworkStores } from '@/store/homeworks';
import { DropdownItem } from '@/models/dropdown';
import { OneOrMultipleAnswers, NumberChoices, BasicYesOrNo, VideoFileSizeLimit } from '@/utils/constants';
import { CreateHomeworkQuestionForAxios, UpdateHomeworkQuestions } from '@/models/question_banks';
import { QuestionStores } from '@/store/questions';
import { ToastProgrammatic } from 'buefy';
import { uploadAttachment } from '@/utils/attachments';
@Component({
    components: { Page, QuestionsLayout, HomeworkQuestionFormTemplate },
})
export default class AddHomeworkQuestions extends Vue {
    // Stores
    courseListStore = CourseStores.list;
    homeworkListStore = HomeworkStores.list;
    homeworkStateStore = HomeworkStores.state;
    questionCreateStore = QuestionStores.create;
    // Dropdowns
    currentCourseData: DropdownItem[] = [];
    currentHomeworkData: DropdownItem[] = [];
    answerOptionList = OneOrMultipleAnswers;
    answerDisplayList = NumberChoices;
    standardInstructionList = BasicYesOrNo;
    // Autofill Variables
    coursePrefixId = -1;
    homeworkPrefixId = -1;

    get title() {
        return `Create Homework Question`;
    }

    get courses() {
        return this.currentCourseData;
    }
    
    get homeworks() {
        return this.currentHomeworkData;
    }

    get answerOptions() {
        return this.answerOptionList;
    }

    get answerDisplays() {
        return this.answerDisplayList;
    }

    get standardInstructions() {
        return this.standardInstructionList;
    }

    mounted() {
        // Autofill based on folder level
        switch(this.homeworkStateStore.homeworkFolderState.length) {
            case 2: {
                this.coursePrefixId = this.homeworkStateStore.homeworkFolderState[this.homeworkStateStore.homeworkFolderState.length - 1].id;
                break;
            }
            case 3: {
                this.coursePrefixId = this.homeworkStateStore.homeworkFolderState[this.homeworkStateStore.homeworkFolderState.length - 2].id;
                this.homeworkPrefixId = this.homeworkStateStore.homeworkFolderState[this.homeworkStateStore.homeworkFolderState.length - 1].id;
                this.getHomeworkList(this.coursePrefixId);
                break;
            }
            default: {
                break;
            }
        }
        // this.courseListStore.retrieveCoursesWithParams({});
        this.courseListStore.retrieveAllSiteCoursesWithParams({});
    }

    // API Functions
    getHomeworkList(courseId: number) {
        this.homeworkListStore.retrieveHomeworks({ courseid: courseId });
    }

    async createHomeworkQuestion(data: UpdateHomeworkQuestions) {
        // Format data to match API
        // Fetch subjectId formId from courses
        const courseIndex = this.courseListStore.response!.objects.findIndex((course) => {
            return data.courseId === course.id;
        });

        if (courseIndex > -1) {
            // General Section
            let input: Partial<CreateHomeworkQuestionForAxios> = {
                subjectId: this.courseListStore.response!.objects[courseIndex].subject.id,
                formId: this.courseListStore.response!.objects[courseIndex].form.id,
                courseId: data.courseId,
                homeworkId: data.homeworkId,
                availability: data.availability ? 1 : 0,
                qType: data.questionType,
                name: data.questionName,
                questionText: data.questionText,
                defaultMark: data.defaultMark,
                generalFeedback: data.generalFeedback,
            };

            // If questionAsset is a video type
            if (data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                data.questionAsset.originalFile && 
                data.questionAsset.originalFile.type.includes(`video/`) &&
                data.questionAsset.thumbnail
            ) {
                const thumbnailPromise = uploadAttachment(
                    data.questionAsset.thumbnail,
                    data.questionAsset.thumbnail?.name.split(`.`)[0],
                    `thumbnail`
                );
                const videoPromise = uploadAttachment(
                    data.questionAsset.originalFile,
                    data.questionAsset.originalFile.name,
                    `video`,
                    VideoFileSizeLimit.questions
                );
                const promises = await Promise.all([thumbnailPromise, videoPromise]);
                input.videoThumbnail = promises[0];
                input.videoUrl = promises[1];
                input.imageUrl = '';
            }

            if (data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                data.questionAsset.originalFile && 
                data.questionAsset.originalFile.type.includes(`image/`)
            ) {
                const image = await uploadAttachment(
                    data.questionAsset.originalFile,
                    data.questionAsset.originalFile?.name,
                    `thumbnail`
                );
                input.imageUrl = image;
                input.videoThumbnail = '';
                input.videoUrl = '';
            }

            if (
                data.questionAsset && 
                typeof data.questionAsset !== 'string' &&
                !data.questionAsset.thumbnail &&
                !data.questionAsset.originalFile
            ) { 
                input.imageUrl = '';
                input.videoUrl = '';
                input.videoThumbnail = '';
            }


            // TODO: Set approved to be dynamic
            // Mcq
            if ('hasMultipleAnswers' in data) {
                input.single = data.hasMultipleAnswers ? 0 : 1; // 1 means true, 0 means false
                input.shuffleAnswers = data.areChoicesShuffled ? 1 : 0
                input.answerNumbering = data.choiceNumberType;
                input.approved = 0;
                input.responseRequired = 0;

                // Format Choices to match  the API
                let answers: string[] = [];
                let fraction: number[] = [];
                let feedback: string[] = [];

                let currentValidAnswers = 0;
                data.choices.forEach((item) => {
                    if (item.isAnswerValid) {
                        currentValidAnswers++;
                    }
                });

                data.choices.forEach((item) => {
                    answers.push(item.choiceText);
                    fraction.push(item.isAnswerValid ? 1/currentValidAnswers : 0);
                    feedback.push(item.feedbackText);
                });

                input.answer = answers;
                input.fraction = fraction;
                input.feedback = feedback;
            } else {
                // Essay
                input.single = 1; // 1 means true, 0 means false
                input.approved = 0;
                input.shuffleAnswers = 0;
                input.answerNumbering = `abc`; // Filler
                input.responseRequired = data.requireText === '1' ? 1 : 0;
                input.answer = [
                    data.answerText
                ];
                input.fraction = [1];
                input.feedback = [''];
            }

            this.questionCreateStore.createNewHomeworkQuestion(input);
        }
    }

    // Parsing Functions
    @Watch(`courseListStore.response`)
    updateCourses() {
        if (this.courseListStore.response) {
            this.currentCourseData = [];
            this.courseListStore.response.objects.forEach((data) => {
                this.currentCourseData.push({
                    text: data.courseName,
                    value: data.id.toString(),
                    uniqueKey: `data_${data.courseName}`
                });
            });
        }
    }

    @Watch(`homeworkListStore.response`)
    updateHomeworks() {
        if (this.homeworkListStore.response) {
            this.currentHomeworkData = [];
            for (let index = 0; index < this.homeworkListStore.response.objects.length; index++) {
                this.currentHomeworkData.push({
                    text: this.homeworkListStore.response.objects[index].name,
                    value: this.homeworkListStore.response.objects[index].id.toString(),
                    uniqueKey: `data_${this.homeworkListStore.response.objects[index].name}`
                });
            }
        }
    }

    @Watch(`questionCreateStore.response`)
    updateHomeworkStates() {
        if (this.questionCreateStore.response) {
            ToastProgrammatic.open({
                type: `is-success`,
                message: `Homework Question successfully created`,
                duration: 3000
            });
            this.homeworkStateStore.updateHomeworkIsModified(true);
            this.redirectToHomeworkManagement();
        }
    }

    // Routing Functions
    redirectToHomeworkManagement() {
        this.$router.push({
            path: `/homeworks`
        });
    }

}
